import { defineStore } from 'pinia'
import type { Suggestion } from '@/types/case'

export const useSuggestionStore = defineStore('suggestion', () => {
  const { adjustments, excluded } = storeToRefs(useSuggestionAdjustmentStore())

  // Suggestions from the API
  const suggestions = ref<Suggestion[] | null>(null)

  // Suggestions with investment adjustments (all suggestions, including excluded ones)
  const suggestionsWithAdjustments = computed(
    () =>
      suggestions.value?.map((suggestion) => {
        const adjustedInvestment = adjustments.value[suggestion.id]
        return adjustedInvestment !== undefined && adjustedInvestment !== null
          ? { ...suggestion, investment: adjustedInvestment }
          : suggestion
      }) ?? null
  )

  // Suggestions with investment adjustments included in the calculation
  const suggestionsForCalculation = computed(
    () =>
      suggestionsWithAdjustments?.value?.filter(
        (suggestion) => !excluded.value[suggestion.id]
      ) ?? null
  )

  /**
   * Populate the store
   * @param currentCase The case to populate the store with
   */

  /**
   *
   */
  function populate(suggestionGroups: Suggestion[] | null) {
    suggestions.value =
      suggestionGroups?.sort((a, b) => a.radId - b.radId) || null
  }

  return {
    suggestions,
    populate,
    suggestionsWithAdjustments,
    suggestionsForCalculation,
  }
})

export type SuggestionStore = ReturnType<typeof useSuggestionStore>
