import { defineStore } from 'pinia'
import type { Maybe } from '@/types/util'
import type { SuggestionCalculationResult } from '@/types/case'

/**
 * Store for investment adjustments and exclusions of suggestions
 */
export const useSuggestionAdjustmentStore = defineStore(
  'suggestionAdjustment',
  () => {
    // Store a map of case IDs
    const cases = ref<Record<string, Maybe<boolean>>>({})

    // Store adjustments as a map of suggestion IDs to adjusted investments
    const adjustments = ref<Record<string, Maybe<number>>>({})
    // Store a map of suggestion IDs that should be excluded from the total investment price
    const excluded = ref<Record<string, Maybe<boolean>>>({})

    const selectedSuggestionsResult = ref<SuggestionCalculationResult | null>(
      null
    )
    /**
     * Set an investment adjustment for a specific suggestion
     * @param id The ID of the suggestion to adjust
     * @param adjustedInvestment The adjusted investment
     */
    function set(id: string, adjustedInvestment: number) {
      adjustments.value[id] = adjustedInvestment
    }

    /**
     * Set the case initial load
     * @param id The ID of the case
     */
    function setCaseInitialLoad(id: string) {
      cases.value[id] = true
    }

    /**
     * Remove an investment adjustment for a specific suggestion
     * @param id The suggestion ID to remove the adjustment for
     */
    function remove(id: string) {
      delete adjustments.value[id]
    }

    /**
     * Exclude a suggestion from the total investment price
     * @param id ID of the suggestion to exclude
     */
    function exclude(id: string) {
      excluded.value[id] = true
    }

    /**
     * Include a suggestion in the total investment price
     * @param id ID of the suggestion to include
     */
    function include(id: string) {
      delete excluded.value[id]
    }

    /**
     * Sets the selected suggestions result to the provided value.
     *
     * @param {PotentialEnergyLabel | null} result - The result to be set as the selected suggestions result.
     *
     *
     */
    function setSelectedSuggestionsResult(
      result: SuggestionCalculationResult | null
    ) {
      selectedSuggestionsResult.value = result ?? null
    }

    return {
      cases,
      adjustments,
      excluded,
      selectedSuggestionsResult,
      set,
      remove,
      include,
      exclude,
      setSelectedSuggestionsResult,
      setCaseInitialLoad,
    }
  },
  persistStore()
)
